import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#18181b",
    },
    text: {
      primary: "#a1a1aa",
    },
  },
});

export default Theme;
