import React from "react";
import { Grid, Card, CardMedia, Box } from "@mui/material";

const Projects = (props) => {
  return (
    <Grid item padding={1.25}>
      <Card sx={{ position: "relative", borderRadius: 2, overflow: "hidden" }}>
        <CardMedia
          component="img"
          image={props.image}
          alt={props.title}
          sx={{
            maxWidth: { xs: 355, sm: 370, lg: 380 },
            width: "100%",
            height: "auto",
            display: "block",
          }}
        />
        {/* Dark Overlay */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.2)", // Dark tint overlay
          }}
        />
      </Card>
    </Grid>
  );
};

export default Projects;
