import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, TextField, Stack, Button, Typography } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

const FORM_ENDPOINT = process.env.REACT_APP_FORM_ENDPOINT;
const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const captchaErrorMessage = `Please tick "I'm not a robot" before submitting`;

const defaultValues = {
  name: "",
  phone: "",
  email: "",
  message: "",
};

const submissionFields = [
  {
    id: "full-name-input",
    name: "name",
    type: "name",
    label: "Full Name",
    rows: 1,
  },
  {
    id: "phone-input",
    name: "phone",
    type: "tel",
    label: "Phone",
    rows: 1,
  },
  {
    id: "email-input",
    name: "email",
    type: "email",
    label: "Email",
    rows: 1,
  },
  {
    id: "message-input",
    name: "message",
    type: "text",
    label: "Message",
    rows: 4,
  },
];

const ContactForm = () => {
  const [formValues, setFormValues] = useState(defaultValues);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [queryParameters] = useSearchParams();

  const submitted = queryParameters.get("submitted");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaValue) {
      setRecaptchaError(true);
    } else {
      setRecaptchaError(false);
      event.target.submit();
    }

    setTimeout(() => {}, 100);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      action={FORM_ENDPOINT}
      method="POST"
    >
      <Stack maxWidth={450} paddingTop={1} paddingLeft={3} paddingRight={3}>
        {submissionFields.map((field) => (
          <TextField
            id={field.id}
            key={field.id}
            name={field.name}
            type={field.type}
            label={field.label}
            rows={field.rows}
            required
            size="small"
            multiline={field.rows > 1}
            value={formValues[field.name]}
            onChange={handleInputChange}
            sx={{
              paddingBottom: 1,
              input: { color: "#a1a1aa" }, // Set text color to white for visibility
              label: { color: "#a1a1aa" }, // Light gray label color for contrast
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#a1a1aa" }, // Light gray border
                "&:hover fieldset": { borderColor: "#a1a1aa" }, // White border on hover
                "&.Mui-focused fieldset": { borderColor: "#a1a1aa" }, // White border when focused
              },
              "& .MuiInputLabel-root": {
                color: "#a1a1aa", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#a1a1aa", // Make the label white when focused
              },
            }}
          />
        ))}
        <ReCAPTCHA
          sitekey={RECAPTCHA_KEY}
          onChange={handleRecaptchaChange}
          theme="dark"
        />
        {recaptchaError && (
          <Typography pt={1} color="error">
            {captchaErrorMessage}
          </Typography>
        )}
        <Box pt={1}>
          {submitted ? (
            <Button variant="contained" type="submit" disabled fullWidth>
              Thank you, we'll be in touch!
            </Button>
          ) : (
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                color: "#000",
                backgroundColor: "#a1a1aa",
                "&:hover": { color: "#000", backgroundColor: "#a1a1aa" },
              }}
            >
              Submit
            </Button>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default ContactForm;
