import React from "react";
import { Card, CardMedia, Box } from "@mui/material";

const PortfolioItem = (props) => {
  return (
    <Card
      elevation={0}
      sx={{ position: "relative", borderRadius: 2, overflow: "hidden" }}
    >
      <CardMedia
        component="img"
        image={props.img}
        alt={props.imgAlt}
        sx={{
          width: "100%", // Ensure the image fills the width of the card
          objectFit: "cover", // Keeps it visually consistent
        }}
      />
      {/* Dark Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        }}
      />
    </Card>
  );
};

export default PortfolioItem;
