import React from "react";
import { Typography, Paper, Container, Box } from "@mui/material";
import { ContactForm } from "../components";

const Contact = () => {
  return (
    <Paper elevation={0} square sx={{ backgroundColor: "#18181b" }}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{ paddingTop: 4, paddingBottom: 5 }}
        align="center"
      >
        <Box paddingLeft={3} paddingRight={3} paddingBottom={1} maxWidth={450}>
          <Typography variant="h6" textAlign="left" sx={{ fontWeight: 400 }}>
            Ready to build something? Let's talk!
          </Typography>
          <Typography variant="body1" textAlign="left">
            Fill in the form below and we'll get back to you as soon as
            possible. Or you can find our phone number, email, and social media
            below.
          </Typography>
        </Box>
        <ContactForm />
      </Container>
    </Paper>
  );
};

export default Contact;
