import React from "react";
import { Paper, Container } from "@mui/material";
import { CustomMasonry } from "../components";
import { useImages } from "../context/ImageContext";

const Portfolio = () => {
  const allImages = useImages();

  return (
    <Paper elevation={0} square sx={{ backgroundColor: "#18181b" }}>
      <Container
        maxWidth="lg"
        align="center"
        sx={{ paddingTop: 4, paddingBottom: 4 }}
      >
        <CustomMasonry allImages={allImages} />
      </Container>
    </Paper>
  );
};

export default Portfolio;
