import React from "react";
import { AppBar, Container, Box, Toolbar, CssBaseline } from "@mui/material";
import { Link } from "react-router-dom";
import { Navbar } from "../components";

const Header = (props) => {
  return (
    <>
      <CssBaseline />
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: "rgba(24, 24, 27, 0.80)", // Transparent dark background
          backdropFilter: "blur(10px)", // Blur effect
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/">
              <Box
                component="img"
                alt={props.imageAlt}
                src={props.imageSrc}
                marginTop={0.5}
                marginBottom={-0.5}
                sx={{
                  width: "auto",
                  height: {
                    xs: 70,
                    sm: 75,
                    md: 80,
                    lg: 85,
                  },
                }}
              />
            </Link>
            <Navbar />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
