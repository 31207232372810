import React from "react";
import { ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Header, Footer, SmoothScroll } from "./components";
import { Home, Portfolio, Contact } from "./pages";
import companyLogo from "./assets/logo.png";
import Theme from "./Theme";
import { ImagesProvider } from "./context/ImageContext";

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <ImagesProvider>
        <Header imageSrc={companyLogo} imageAlt="Red Sands Fabrication" />
        <SmoothScroll>
          <Routes>
            <Route path="*" element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </SmoothScroll>
        <Footer />
      </ImagesProvider>
    </ThemeProvider>
  );
};

export default App;
